




































import { Component, Vue } from 'vue-property-decorator'
import { User } from '@/store/modules/user'

@Component
export default class AddNewUserModal extends Vue {
  user: User = {
    key: '',
    firstName: '',
    secondName: '',
    email: '',
    roles: [],
    SAMLNameID: '',
    traditionalAuth: false,
    newPassword: '',
    activated: false,
    locked: false
  }

  confirmPassword: string = ''

  validateNewPassword () {
    const _password = this.user.newPassword as string
    if (_password.length < 6) {
      return 'Password must be 6 characters in length'
    }
    if (_password.length > 30) {
      return 'Password too long'
    }
    if (_password !== this.confirmPassword) {
      return 'Passwords must match'
    }
    return ''
  }

  get canSave () {
    if (this.user.traditionalAuth && this.user.newPassword && this.user.newPassword.length && this.validateNewPassword().length) {
      return false
    }
    if (!this.user.roles.length) {
      return false
    }
    return true
  }

  confirmAddUser () {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to add this user?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      this.$store.dispatch('saveUser', this.user).then(() => {
        this.$store.dispatch('loadUsers')
        this.$bvModal.hide('add-new-user-modal')
      })
    })
  }

  closeModal () {
    this.$bvModal.hide('add-new-user-modal')
  }
}
