





























import { Component, Vue } from 'vue-property-decorator'
import { hasPermissions } from '@/utils/auth'
import AddNewUserModal from './AddNewUser.modal.vue'

@Component({
  components: {
    AddNewUserModal
  }
})
export default class UsersAdminDashboard extends Vue {
  hasPermissions = hasPermissions
  breadcrumbItems: any[] = [
    {
      text: 'Admin',
      to: { name: 'admin' }
    },
    {
      text: 'Manage users',
      to: { name: 'manage-users' }
    }
  ]

  tableFields: any[] = [{
    key: 'locked',
    label: 'Locked',
    sortable: true,
    class: 'text-center'
  },
  {
    key: 'activated',
    label: 'Activated',
    sortable: true,
    class: 'text-center'
  },
  {
    key: 'firstName',
    label: 'First name',
    sortable: true
  },
  {
    key: 'secondName',
    label: 'Last name',
    sortable: true
  },
  {
    key: 'email',
    label: 'Email',
    sortable: true,
    class: 'text-nowrap'
  },
  {
    key: 'rolesString',
    label: 'Roles',
    sortable: true
  },
  {
    key: 'show_details',
    label: 'Options'
  }]

  // props
  get users () {
    return this.$store.getters.getUsers.map((user: any) => {
      user.rolesString = user.roles.map((r: any) => {
        return r.name
      }).join(', ')
      return user
    })
  }

  mounted () {
    this.$store.dispatch('loadUsers')
    this.$store.dispatch('loadProducts')
  }

  addUser () {
    this.$bvModal.show('add-new-user-modal')
  }
}
